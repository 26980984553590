<template>
	<div>
		<div style="background: #fff;">
			<div class="headTop main">
				<div class="logoCls">
					<img :src="webSiteObj.logo" alt="" v-if="webSiteObj" @click="handleLogoPath">
				</div>
				<!-- <div class="loginCls">
					<div class="loginIcon">
						<img :src="avatarImg" alt="">
					</div>
					<div class="loginText" style="margin-left: 16px;">{{name}}</div>
					<div @click="leaveLogin" v-if="isLogin" style="color:red;cursor: pointer;">退出登录</div>
					<div v-if="!isLogin" @click="Loging" style="color:red;cursor: pointer;">请登录</div>
				</div> -->
			</div>
		</div>
		<div class="headBottom" v-if="navcolor.backgroundColorLogo!==null"
			:style="{backgroundColor:navcolor.backgroundColorLogo}">
			<!-- <div class="logoCls">
				<img :src="webSiteObj.logo" alt="" v-if="webSiteObj" @click="handleLogoPath">
			</div> -->
			<ul v-if="navcolor.fontColorLogo!==null">
				<li v-for="(it,i) in menuList" :key="i" :class="currt === it.sort ? 'liClick' : ''"
					:style="{color:navcolor.fontColorLogo}" @click="menuEvt(it,$event)">{{it.name}}</li>
				<li class="loginCls">
					<div class="loginIcon">
						<img :src="avatarImg" alt="">
					</div>
					<div class="loginText" style="margin-left: 16px;">{{name}}</div>
					<div @click="leaveLogin" v-if="isLogin" style="color:red;cursor: pointer;">退出登录</div>
					<div v-if="!isLogin" @click="Loging" style="color:red;cursor: pointer;">请登录</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	export default {
		// props:['name'],
		data() {
			return {
				currt: 1,
				// webSiteObj: {},
				// token: '',
				// userInfo: {},
				// name: '',
				// imgUrl: ''

			}
		},
		watch: {
			'$route'(to, from) {
				this.$router.go(0);
			}
		},
		created() {
			// console.log(this.$refs.liClick);
			if (this.$route.query.isUpdate === '0') {
				this.$router.push({
					path: '/personalCenter',
					query: {
						isUpdate: 0
					}
				})
				this.menuList.forEach(ele => {
					if (ele.path === '/personalCenter') {
						this.currt = ele.sort
					}
				});
			} else if (this.$route.isUpdate === '1') {
				this.currt = 1
			}
			//获取站点信息
			// this.$http.post('userWebsite').then(res => {
			//   if(res.code == 200){
			//     this.webSiteObj = res.data
			//   }
			// })
			// ///判断用户是否登录
			// this.token = localStorage.getItem('token')
			// if (this.token) {
			//   this.currt = sessionStorage.getItem('menuIndex') -0
			//   this.loginStatus = true
			//   this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
			//   this.userInfo.map(it => {
			//     it.label = it.label.split(':')
			//     if (it.label[1] === "name") {
			//       this.name = it.value
			//       this.getTxImg()
			//     }
			//   })
			// } else {
			//   this.loginStatus = false
			// }
			if (sessionStorage.getItem('menuIndex')) {
				this.currt = sessionStorage.getItem('menuIndex') - 0
			}
			if (this.$route.query.index) {
				this.currt = this.$route.query.index - 0
				sessionStorage.setItem('menuIndex', this.currt)
			}
		},
		computed: {
			...mapState(['webSiteObj', 'menuList', 'isLogin', 'userInfo', 'avatar', 'navcolor']),
			currentUrl() {
				return location.protocol + '//' + location.host + '/#/'
			},
			name() {
				let str = '';
				let name = '';
				if (this.userInfo) {
					console.log(111,this.userInfo)
					this.userInfo.map(it => {
						let label = it.label.split(':');
						if (label[1] === "name") {
							str = it.value
						}
					})
				}
				if (str.length < 2) {
					name = str
				} else if (str.length === 2) {
					name = str.slice(0, 1) + '*'
				} else {
					name = str.slice(0, 1) + '*' + str.slice(-1)
				}
				return name
			},
			avatarImg() {
				let img = require('../assets/imgs/3.png');
				if (this.avatar) {
					img = this.avatar
				}
				return img
			},
		},
		methods: {
			// 获取用户头像
			// getTxImg(){
			//   if(this.token){
			//     this.$http.post('queryLoginPhoto').then(res=>{
			//     this.imgUrl = res.data
			//   })
			//   }
			// },
			handleLogoPath() {
				if (this.menuList[0].content == 8) {
					window.location.href = this.menuList[0].jumpUrl
				} else {
					this.$router.push({
						path: '/',
						query: {
							isUpdate: 1,
							index: 1
						}
					})
				}
			},
			menuEvt(it) {

				this.currt = it.sort
				sessionStorage.setItem('menuIndex', it.sort)
				if (it.content !== 8) {
					if (it.contentWord) {
						sessionStorage.setItem('richText', it.contentWord)
						sessionStorage.setItem('menuName', it.name)
						this.$router.push({
							path: it.path,
							query: {
								index: it.sort
							}
						})
					} else {
						this.$router.push(it.path)
					}
				} else {
					window.location.href = it.jumpUrl
				}

			},
			toLogin() {
				if (this.isLogin) {
					return
				} else {
					this.$router.push({
						path: '/',
						query: {
							index: 1
						}
					})
					sessionStorage.setItem('menuIndex', 1)
				}
			},
			// 登录
			Loging() {
				localStorage.removeItem('isUpdate')
				this.$router.push({
					path: '/login',
					// query: {index: 1}
				})

			},
			leaveLogin() {
				this.$store.dispatch('LoginOut')
				// this.$http.post('loginOutApi').then(res => {
				//   if (res.code === 200) {
				//     this.$message.success('退出登录成功')
				//     localStorage.removeItem('userInfo')
				//     localStorage.removeItem('token')
				//    sessionStorage.setItem('menuIndex', 1)
				//    this.$emit('reload',true)
				//     // this.$router.push({
				//     //   path: '/',
				//     //   query: {index: 1}
				//     // })
				//     // this.$router.push('/login')
				//     this.name = ''
				//     this.loginStatus = false
				//     this.$emit('reload',false)
				//   } else {
				//     this.$message.error(res.message)
				//   }
				// })
			}
		}

	}
</script>

<style lang="less" scoped>
	.main {
		width: 1200px;
		margin: 0 auto;
	}

	.headTop {
		display: flex;
		justify-content: space-between;

		.logoCls {
			font-size: 39px;
			font-family: SourceHanSansCN;
			font-weight: bold;
			color: #222222;
			cursor: pointer;

			img {
				width: 800px;
				height: 80px;
				margin-top: 10px;
			}
		}

		.loginCls {
			display: flex;
			justify-content: space-between;
			line-height: 100px;
			font-size: 20px;

			.loginIcon {
				margin: 22px;
				margin-right: 0;
				border-radius: 50%;

				img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
					display: block;
					margin: 0 auto;

					// border: 1px solid #000;
				}
			}

			.loginText {
				font-size: 18px;
				color: #333333;
				cursor: pointer;
				margin: 0 10px;
			}

		}
	}

	.headBottom {
		// width: 1200px;
		width: 100%;
		height: 72px;
		// background: #E51E0B;
		line-height: 72px;
		display: flex;
		ul {
			display: flex;
			justify-content: space-between;
			width: 1200px;
			margin: 0 auto;

			li {
				height: 62px;
				font-size: 18px;
				font-family: PingFang SC;
				font-weight: bold;
				color: #FFFFFF;
				cursor: pointer;
			}
				.loginCls {
			display: flex;
			justify-content: space-between;
			align-items: center;
			line-height: 70px;
			font-size: 20px;
			height: 70px;
			.loginIcon {
				margin: 22px;
				margin-right: 0;
				border-radius: 50%;

				img {
					width: 50px;
					height: 50px;
					border-radius: 50%;
					display: block;
					margin: 0 auto;
				}
			}

			.loginText {
				font-size: 18px;
				color: #fff;
				cursor: pointer;
				margin: 0 10px;
			}

		}
			.liClick {
				border-bottom: 2px solid #FFFFFF;
			}
		}
	}
</style>
